
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import { useAppStore } from '@bd/admin/store'
import {
  AdminUserCalendarEventsParams,
  UserCalendarEventDto,
  UserRole,
} from '@bd/api/common'
import {
  CalendarDateTitle,
  CalendarView,
  useCalendarLocalState,
  UserType,
} from '@bd/components'
import { LocalIsoDate } from '@bd/helpers'
import { computed, defineComponent, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import AgentDropdown from '@bd/admin/components/AgentDropdown/AgentDropdown.vue'
import { AgentContent } from '@bd/admin/types'
import { useSelectedAgent } from '@bd/admin/use/useSelectedAgent'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Calendar',
  components: {
    AppContentLayout,
    CalendarView,
    CalendarDateTitle,
    AgentDropdown,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()

    const agents = computed(() => store.state.agents?.agents.content || [])
    const loggedUser = computed(() => store.state.userProfile?.user)
    const isAgent = computed(() =>
      loggedUser.value?.roles.includes(UserRole.AGENT),
    )
    const storedSelectedAgentId = computed(
      () => store.state.calendar?.selectedAgentId,
    )

    const selectedAgent = useSelectedAgent(() => {
      if (storedSelectedAgentId.value) {
        return agents.value.find(
          (agent: AgentContent) => agent.id === storedSelectedAgentId.value,
        )
      }
      if (!isAgent.value) {
        return agents.value[0]
      }
      const { id, firstName, lastName } = loggedUser.value!
      return {
        id,
        name: `${firstName} ${lastName}`,
      } as AgentContent
    })

    const {
      selectedDay,
      navigatedDate,
      onSelectedDayChange,
    } = useCalendarLocalState(store.state.calendar?.selectedDate)

    const timeSlots = computed(() => store.state.staticData?.timeSlots ?? [])
    const todayCalendarEvents = computed(
      () => store.state.calendar?.todayEvents ?? [],
    )

    const fetchTodayEvents = async () => {
      const params: AdminUserCalendarEventsParams = {
        date: selectedDay.value,
        agentId: store.state.calendar!.selectedAgentId!,
      }
      await store.dispatch('calendar/userCalendarEvents', params)
    }

    const selectedDayChange = (day: LocalIsoDate) => {
      if (onSelectedDayChange(day)) {
        store.commit('calendar/SET_SELECTED_DATE', day)
        fetchTodayEvents()
      }
    }

    const storeSelectedAgentId = async (agentId?: number) => {
      await store.dispatch('calendar/setSelectedAgentId', agentId)
    }

    const onAgentSelect = async (agentId: number) => {
      await storeSelectedAgentId(agentId)
      fetchTodayEvents()
    }

    const fetchAgents = async () => {
      await store.dispatch('agents/agentList')
    }

    const eventTileClicked = (event: UserCalendarEventDto) => {
      router.push({
        path: `/calendar/event/${event.userCalendarEventId}`,
      })
    }

    onMounted(async () => {
      await fetchAgents()
      await storeSelectedAgentId(selectedAgent.value!.id)
      fetchTodayEvents()
    })

    return {
      t,
      timeSlots,
      agents,
      selectedDay,
      navigatedDate,
      todayCalendarEvents,
      UserType,
      selectedDayChange,
      selectedAgent,
      onAgentSelect,
      eventTileClicked,
    }
  },
})
